<template>
  <div class="grow relative">
    <div
      ref="search"
      class="flex items-center rounded-[8px] overflow-hidden komsearch border-[1px] border-[#E2E2E2]"
      :class="loading ? 'bg-[#efefef]' : 'bg-white'"
    >
      <div class="flex flex-col gap-[5px] w-full">
        <div
          v-if="selectedValue.length > 0"
          class="flex flex-wrap gap-[8px] text-[#333333] p-[10px] pb-0"
        >
          <div
            v-for="item, idx in selectedValue"
            :key="`label-${idx}`"
            class="flex items-center gap-[5px] px-[10px] h-[30px] rounded-[4px] bg-[#FFECE9]"
          >
            <span class="text-[12px]">{{ item.text }}</span>
            <i
              class="k-close-circle text-[14px] cursor-pointer"
              @click="() => removeOption(item.value)"
            />
          </div>
        </div>
        <b-form-input
          :id="name"
          ref="searchMulti"
          :value="modelSearch"
          autocomplete="off"
          type="search"
          :placeholder="placeholder"
          debounce="500"
          class="border-0 shadow-none !text-[14px] !p-[10px]"
          size="lg"
          :disabled="loading"
          @focus="onFocus"
          @blur="onBlur"
          @update="(value) => $emit('update:modelSearch', value)"
        />
      </div>
      <b-spinner
        v-if="loading"
        small
        class="mr-[16px]"
      />
      <i
        v-else
        class="k-arrow-down-1 mr-[12px] text-[24px] cursor-pointer"
        @click="$refs.searchMulti.focus()"
      />
    </div>
    <div
      class="absolute z-[1] komsearch rounded-[8px] shadow-[0_4px_4px_0] shadow-black/25 bg-white transition-opacity duration-300 hover:!pointer-events-auto hover:!opacity-100"
      :class="isFocus ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'"
      :style="{ top: `${height}px` }"
      @mouseover="isFocus = true"
      @mouseleave="isFocus = false, $refs.searchMulti.blur()"
    >
      <div v-if="options.length > 0">
        <span class="block truncate w-full text-[12px] text-[#FBA63C] p-[10px]">Hanya bisa memilih maksimal 4</span>
        <b-form-checkbox-group
          :id="`${name}-group`"
          :checked="modelValue"
          :name="`${name}-group`"
          class="p-[10px] pt-0 overflow-y-auto"
          style="max-height: 248px;"
          stacked
        >
          <div
            v-for="(item, idx) in options"
            :key="`option-${idx}`"
            class="p-[10px] [&_label]:w-full"
            :class="{ '[&_label]:cursor-pointer': !disabledOption(item) }"
          >
            <b-form-checkbox
              :id="item.value"
              :value="item.value"
              :name="name"
              :disabled="disabledOption(item)"
              @change="(e) => onChange(item, e)"
            >
              {{ item.text }}
            </b-form-checkbox>
          </div>
        </b-form-checkbox-group>
      </div>
      <span
        v-else
        class="p-[10px] text-[12px] text-[#333333] font-semibold block w-full text-center"
      >Tidak Ada Opsi</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'check',
    },
    placeholder: {
      type: String,
      default: 'Pilih Talent Lead',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelSearch: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFocus: false,
      localValue: '',
      selectedValue: [],
      height: 48.5,
    }
  },
  watch: {
    isFocus(value) {
      if (!value) {
        this.$emit('onBlur', JSON.stringify(this.modelValue) === this.localValue)
      }
    },
    modelValue(value) {
      if (value.length === 0) this.selectedValue = []
    },
  },
  methods: {
    onFocus() {
      this.isFocus = true
      this.localValue = JSON.stringify(this.modelValue)
    },
    onBlur({ relatedTarget }) {
      const group = document.getElementById(`${this.name}-group`)
      if (relatedTarget !== group) {
        this.isFocus = false
      }
    },
    onChange(item, change) {
      if (change.length < this.modelValue.length) {
        this.selectedValue = this.selectedValue.filter(v => v.value !== item.value)
      } else {
        this.selectedValue = [...this.selectedValue, item]
      }
      this.$emit('update:modelValue', change)
      setTimeout(() => { this.height = this.$refs.search.clientHeight + 7.5 }, 0)
    },
    removeOption(value) {
      this.selectedValue = this.selectedValue.filter(v => v.value !== value)
      const model = this.modelValue.filter(v => this.selectedValue.map(e => e.value).includes(v))
      this.$emit('update:modelValue', model)
      this.$emit('onBlur', false)
      setTimeout(() => { this.height = this.$refs.search.clientHeight + 7.5 }, 0)
    },
    disabledOption(item) {
      return this.modelValue.length >= 4 && !this.modelValue.includes(item.value)
    },
  },
}
</script>

<style scoped>
#search-talent-lead {
  font-size: 14px;
}

.komsearch {
  width: 100%;
}

.komsearch:has(#search-talent-lead:focus) {
  border-color: rgb(249, 80, 49);
}

@media screen and (min-width: 914px) {
    .komsearch {
        max-width: 380px;
    }
}
</style>
