<template>
  <div :class="'border rounded-[8px] flex flex-col p-[16px] ' + containerClass">
    <div class="flex gap-[8px] items-center pb-[10px]">
      <span :class="`${titleClass ? titleClass : 'text-[#333] text-[14px] font-medium'}`">{{ title }}</span>
      <span
        v-if="appendTitleIcon"
        v-b-tooltip.hover.top="tooltipText"
        :class="`${appendTitleIcon}`"
      />
    </div>
    <small class="text-muted capitalize">talent</small>
    <div :class="'font-semibold text-[20px] text-[#333] ' + contentClass">
      {{ content }}
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  props: {
    containerClass: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    appendTitleIcon: {
      type: String,
      default: '',
    },
    content: {
      type: Number,
      default: 0,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
}
</script>
