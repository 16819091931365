<template>
  <BCard>
    <div class="text-black text-2xl font-semibold mb-2">
      Invoice
    </div>

    <BTabs
      v-model="tabIndex"
      class="mt-2"
      content-class="mt-1"
      pills
      active-nav-item-class="rounded-pill"
    >
      <BTab
        title="Aktif"
        active
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <Active />
      </BTab>
      <BTab
        :active="$route.query.tab === 'disbursement'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          <div class="flex items-center gap-3">
            Disbursement
            <span
              class="total-failed text-xs h-[18px] px-[5px] pt-[3px] grid place-items-center rounded-[9px]"
              :class="$route.query.tab !== 'disbursement' ? 'bg-[#F95031] text-white' : 'bg-white text-[#F95031]'"
            >
              {{ totalFailed }}
            </span>
          </div>
        </template>
        <Disbursement :disbursement="totalFailed" />
      </BTab>
      <BTab
        :active="$route.query.tab === 'sharing-fee'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <template #title>
          <div class="flex items-center gap-3">
            Sharing Fee
          </div>
        </template>
        <SharingFee />
      </BTab>
      <BTab
        title="Riwayat"
        :active="$route.query.tab === 'history'"
        lazy
        title-item-class="bg-[#F8F8F8] mr-[24px] rounded-[25px]"
      >
        <History />
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import Active from './status/Active.vue'
import History from './status/History.vue'
import Disbursement from './status/Disbursement.vue'
import SharingFee from './status/SharingFee.vue'

export default {
  components: {
    Active,
    History,
    Disbursement,
    SharingFee,
  },
  data() {
    return {
      tabIndex: 0,
      totalFailed: 0,
      tabs: ['active', 'disbursement', 'sharing-fee', 'history'],
    }
  },
  watch: {
    tabIndex(newTab, oldtab) {
      const tab = this.tabs[newTab]
      if (this.$route.query.tab !== tab) this.$router.replace({ query: { tab } })
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2' || bvEvent.componentId === 'dropdown-3' || bvEvent.componentId === 'dropdown-4') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })

    const tab = this.tabs[0]
    if (this.$route.query.tab !== tab) this.$router.replace({ query: { tab } })

    this.getTotalFailed()
  },
  methods: {
    async getTotalFailed() {
      const url = 'v1/invoices/count_failed_disbursement'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          if (data) this.totalFailed = data.total
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
  },
}
</script>
