<template>
  <div>
    <div class="py-[1rem] px-[1.5rem] flex gap-[1rem] flex-wrap w-full">
      <div class="d-flex align-items-center rounded-[8px] h-[43.5px] overflow-hidden komsearch">
        <i
          class="k-search-normal-1 ml-[12px] text-[24px] cursor-pointer"
          @click="$refs.searchInvoice.focus()"
        />
        <b-form-input
          id="search-invoice-disbursment"
          ref="searchInvoice"
          v-model="keyword"
          type="search"
          placeholder="Cari Nama atau No Partner"
          debounce="500"
          class="border-0 shadow-none"
          size="lg"
          @input="handleSearch()"
        />
      </div>
      <VSelect
        v-model="isEarlyPayment"
        :reduce="opt => opt.value"
        :options="isEarlyPaymentOptions"
        placeholder="Semua"
        class="w-full max-w-[200px] [&>:first-child]:h-[43.5px] [&>:first-child]:!rounded-[8px] [&>:first-child]:!border-[#e2e2e2]"
        @input="getListData"
      />
      <div class="w-[250px] h-[43.5px] [&_.month-year-display]:!rounded-[8px] [&_.month-year-display]:!border-[#e2e2e2] [&_.month-year-display]:!shadow-none container-date">
        <MonthlyPicker
          v-model="periode"
          :month-labels="monthlabel"
          date-format="MMM YYYY"
          :clear-option="false"
          place-holder="-"
          selected-background-color="#F95301"
          class="month-picker max-w-[100px]"
          @selected="getListData"
        />
      </div>
    </div>
    <div class="d-flex py-[20px] border-t items-center">
      <span class="text-8 text--dark font-bold">Total Disbursement: {{ disbursement }}</span>
      <Button
        variant="border-[#F95031]"
        class="ml-2 px-[8px] py-[4px] refresh-btn rounded-lg"
        @click="getListData()"
      >
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/refresh-2.svg"
          height="16"
          alt="refresh"
        >
      </Button>
      <BButton
        variant="primary"
        class="ml-auto px-3"
        :disabled="checkedList.length === 0"
        @click="sendDisbursement()"
      >
        <strong>Disburse</strong>
      </BButton>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 384px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #head(checkbox)>
            <b-form-checkbox
              v-model="checkAll"
              :value="true"
              :unchecked-value="false"
              @change="handleCheckAll()"
            />
          </template>
          <template #cell(checkbox)="data">
            <b-form-checkbox
              v-model="checkedList"
              :value="data.item.disbursement_id"
              :unchecked-value="null"
              :disabled="data.item.disbursement_status !== 'FAILED' && data.item.disbursement_status !== 'CREATED'"
            />
          </template>
          <template #cell(partner_name)="data">
            {{ `${data.item.partner_name} (${data.item.no_partner})` }}
          </template>
          <template #cell(nominal)="data">
            <div class="flex flex-col gap-2 items-center">
              <span>{{ IDR(data.item.talent_salary) }}</span>
              <span
                :class="data.item.is_early_payment ? 'status-payment-green' : 'status-payment-blue'"
                class="status-payment rounded-full whitespace-nowrap"
              >{{ data.item.is_early_payment ? 'Pembayaran Awal' : 'Pembayaran Akhir' }}</span>
            </div>
          </template>
          <template #cell(status)="data">
            <span
              v-if="data.item.disbursement_status.toUpperCase() === 'FAILED'"
              class="status status-red d-flex items-center w-fit gap-3"
            >failed
              <span
                v-b-tooltip.hover.top="data.item.failed_message || '-'"
                class="k-info-circle-1 text-danger"
              />
            </span>
            <span
              v-if="data.item.disbursement_status.toUpperCase() === 'PENDING'"
              class="status status-yellow"
            >pending</span>
            <span
              v-if="data.item.disbursement_status.toUpperCase() === 'WAITING'"
              class="status status-yellow"
            >waiting</span>
            <span
              v-if="data.item.disbursement_status.toUpperCase() === 'COMPLETED'"
              class="status status-green"
            >success</span>
            <span
              v-if="data.item.disbursement_status.toUpperCase() === 'CREATED'"
              class="status status-green"
            >created</span>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import MonthlyPicker from 'vue-monthly-picker'
import VSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { required } from '@validations'
import { LABELMONTH, YEAR_MONTH_DAY } from '@/libs/filterDate'
import { tableFailedDisburse, isEarlyPaymentOptions } from '../config'
import '@core/scss/vue/libs/vue-select.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    MonthlyPicker,
    VSelect,
  },
  props: {
    disbursement: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      IDR,
      fields: tableFailedDisburse,
      loading: false,
      loadingSubmit: false,
      alertError,
      alertSuccess,
      required,
      YEAR_MONTH_DAY,
      limit: 20,
      offset: 0,
      keyword: '',
      checkAll: false,

      periode: null,
      items: [],
      monthlabel: LABELMONTH,
      indexSelected: null,
      checkedList: [],
      isEarlyPaymentOptions,
      isEarlyPayment: '',
    }
  },
  computed: {
    maxDatePicker() { return this.$moment().endOf('month') },
    allItems() {
      return this.items.filter(e => e.disbursement_status === 'FAILED' || e.disbursement_status === 'CREATED').map(e => e.disbursement_id)
    },
  },
  watch: {
    checkedList(val) {
      if (val.length > 0 && val.length === this.allItems.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    },
    allItems(val) {
      if (val.length > 0 && val.length === this.checkedList.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
    },
  },
  async mounted() {
    const defaultDate = new Date()
    const year = defaultDate.getFullYear()
    const month = defaultDate.getMonth()

    this.periode = YEAR_MONTH_DAY(new Date(year, month - 1))

    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')

      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&is_early_payment=${this.isEarlyPayment}&invoice_date=${this.periode ? YEAR_MONTH_DAY(this.periode) : ''}`
      const url = `v1/invoices/failed_disbursement?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          if (data) this.items = data
          else this.items = []
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&invoice_date=${this.periode ? YEAR_MONTH_DAY(this.periode) : ''}`
        const url = `v1/invoices/failed_disbursement?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    async sendDisbursement(id) {
      this.loadingSubmit = true
      const payload = {
        disbursement_id: this.checkedList,
      }

      const url = 'v1/invoices/disbursement/retry'
      await komtimAxiosIns
        .post(url, payload)
        .then(() => {
          const text = 'Berhasil mendisburse ulang'
          this.alertSuccess(text)

          this.getListData()
          this.loadingSubmit = false
          this.checkedList = []
          this.checkAll = false
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    handleCheckAll() {
      if (this.checkAll) {
        this.checkedList = this.allItems
      } else {
        this.checkedList = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  padding: 1px 10px;
  text-transform: capitalize;
  border-radius: 5px;

  &-red {
    background: #FFEDED;
    color: #E31A1A;
  }
  &-orange {
    background: #fdc279;
    color: #ff8c00;;
  }
  &-yellow {
    background: #FFF2E2;
    color: #FBA63C;
  }
  &-green {
    background: #DCF3EB;
    color: #34A770;
  }
}
.month-picker .input, .month-picker .month-picker-wrapper, .month-picker .month-year-label {
  height: 100% !important;
}
.month-picker .vue-monthly-picker .month-picker-wrapper {
  min-width: 150px !important;
}
.refresh-btn {
  border: 1px solid #F95031;
}
.status-payment {
  padding: 1px 10px;
  text-transform: capitalize;
  font-size: small;

  &-blue {
    background: #DFF3FF;
    color: #08A0F7;
  }

  &-green {
    background: #DCF3EB;
    color: #34A770;
  }
}
</style>

<style scoped>
#search-invoice-disbursment {
  font-size: 14px;
}

.komsearch {
  width: 100%;
  border: 1px solid #E2E2E2;
}

.komsearch:has(#search-invoice-disbursment:focus) {
  border-color: rgb(249, 80, 49);
}

@media screen and (min-width: 900px) {
    .komsearch {
        max-width: 324px;
    }
}
</style>

<style lang="scss">
.month-picker .input, .month-picker .month-picker-wrapper, .month-picker .month-year-label {
  height: 100% !important;
}
.container-date > div, .container-date > div.vue-daterange-picker > div.form-control.reportrange-text, .form-control, .reportrange-text {
  height: 100% !important;
}
</style>
